<script setup>

    import { Head, useForm,Link } from '@inertiajs/vue3'
    import JetAuthenticationCard from '../../Components/AuthenticationCard.vue';
    import JetAuthenticationCardLogo from '../../Components/AuthenticationCardLogo.vue';
    import JetButton from '../../Components/Button.vue';
    import JetInput from '../../Components/Input.vue';
    import JetCheckbox from '../../Components/Checkbox.vue';
    import JetLabel from '../../Components/Label.vue';
    import JetValidationErrors from '../../Components/ValidationErrors.vue';
    import EyeEyeSlash from "@/Components/EyeEyeSlash.vue";
    import {ref} from "vue";

    defineProps({
        canResetPassword: Boolean,
        status: String,
    });
    const form = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const input_mod = ref('password');
    const submit = () => {
        form.transform(data => ({
            ...data,
            remember: form.remember ? 'on' : '',
        })).post(route('login'), {
            onFinish: () => form.reset('password'),
        });
    };
    function toogleShowPassowrd(){
        input_mod.value = (input_mod.value==='password')?'text':'password';
    }
</script>

<template>
    <Head title="Log in" />
    <JetAuthenticationCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <JetValidationErrors class="mb-4" />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <JetLabel for="email" value="Email" />
                <JetInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="email"

                />
            </div>

            <div class="mt-4">
                <JetLabel for="password" value="Password" />
                <div class="relative">
                    <JetInput
                        id="password"
                        v-model="form.password"
                        :type="input_mod"
                        class="mt-1 block w-full"
                        required
                        autocomplete="current-password"
                    />

                    <eye-eye-slash
                        :InputMod="input_mod"
                        @click="toogleShowPassowrd"
                    />
                </div>

            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <JetCheckbox v-model:checked="form.remember" name="remember" />
                    <span class="ml-2 text-sm text-gray-600">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link v-if="canResetPassword" :href="route('password.request')" class="underline text-sm text-gray-600 hover:text-gray-900">
                    Forgot your password?
                </Link>

                <JetButton class="ml-4 bg-gradient-to-t from-gray-400 to-gray-500 hover:from-gray-500 hover:to-gray-700
                     shadow-xl text-gray-100" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Log in
                </JetButton>
            </div>
        </form>

    </JetAuthenticationCard>
</template>
